export default {
    name: 'MarketplaceMixin',
    provide: function () {
        return {
            marketplacePage: this
        }
    },
    data() {
        return {
        }
    },
    inject: {
        erplayout: {
            default() {
                console.error('Main needs to be child of ErpLayout')
            }
        }
    },
    beforeMount() {
    },
    mounted() {
        window.setTimeout(() => {
            this.erplayout.erpheader.setMenuModuleToggle(true)
        }, 10)
        document.body.classList.add('marketplace-body')
        // this.erplayout.erpheader.menu.setActiveMenu('suporte')
    },
    destroyed() {
        document.body.classList.remove('marketplace-body')
    },
    beforeDestroy() {
        this.erplayout.erpheader.setMenuModuleToggle(false)
    },
    methods: {
    }
}
