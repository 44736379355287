export default {
  title: 'Suporte',
  default: 'dash',
  shortName: 'SUPORTE',
  menu: [
    {
      id: 'dash',
      title: 'Suporte',
      icon: 'headset',
      style: 'light',
      route: 'suporte.cliente.dashboard'
    },
    {
      id: 'tasks',
      title: 'Tickets',
      icon: 'comments-question-check',
      style: 'light',
      url: '/#/suporte/tarefas'
    },
    {
      id: 'marketplace',
      title: 'Marketplace',
      icon: 'fa-duotone fa-bag-shopping',
      forceIconClass: true,
      style: 'light',
      route: 'marketplace.dashboard'
    },
    {
      id: 'project',
      title: 'Changelog',
      icon: 'code-branch',
      disabled: true,
      style: 'light',
    },
    {
      id: 'api',
      title: 'API',
      icon: 'code',
      disabled: true,
      style: 'light',
    },
    {
      id: 'contract',
      title: 'Licença e Contrato',
      icon: 'file-contract',
      disabled: true,
      style: 'light',
    },
    {
      id: 'invoices',
      roles: ['ROLE_ADMIN', 'ROLE_FINANCEIRO'],
      title: 'Faturamento',
      icon: 'money-check-dollar',
      route: 'suporte.cliente.faturas',
      style: 'light',
    },
  ]
}
