<script>
import Layout from '../layout/Layout'
import Menu from '@/components/suporte/cliente/menu'
import DefaultMixin from './mixin'

export default {
  name: 'MarketplacePage',
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
    }
  },
  computed: {
    menu () {
      return Menu
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Marketplace',
    name: 'Marketplace'
  }
}
</script>

<template>
  <layout class="marketplace-page" :menu="menu" menu-style="black" menu-active="config" menu-name="SUPORTE" :folded="true" no-title hfull>
    <router-view />
  </layout>
</template>

<style lang="stylus" src="./assets/default.styl"></style>
